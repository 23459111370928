(() => {
    document.addEventListener('DOMContentLoaded', () => {
        const parentContainer = document.querySelector('.home#mainPage');
        const animContainer = document.querySelector('#bodymovin');
        const contentContainer = document.querySelector('.container');
        const filePath = getFilePath();

        const animData = {
            wrapper: animContainer,
            animType: 'svg',
            loop: false,
            prerender: true,
            autoplay: true,
            path: filePath,
            rendererSettings: {
                viewBoxOnly: true,
                preserveAspectRatio: 'xMidYMid slice',
                progressiveLoad: false,
            }
        };

        const anim = bodymovin.loadAnimation(animData);
        anim.addEventListener('complete', () => {
            contentContainer.classList.add('display');
            setTimeout(() => contentContainer.classList.add('show'), 0);
            anim.destroy();
            parentContainer.removeChild(animContainer);
        });

    });

    function getFilePath() {
        return window.matchMedia("(orientation: portrait)").matches ? 'static/mobile.json' : 'static/desktop.json';
    }
})();