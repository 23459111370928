const SOOTHSAYINGS = [
    `Досить повторювати старі помилки, час здійснювати нові!`,
    `Якщо п'ятий млинець комом, ліпи колобків`,
    `Неприємності – вдалий матеріал для захопливих історій`,
    `Як зробити так, щоб все вийшло? Є суперідея – спробуй ще раз`,
    `«Хай-тек» чи «хай так»? Визначся зі стилем свого життя`,
    `Пам'ятай, в житті може статися що завгодно. Навіть нічого`,
    `Не втрачай можливість зробити те, що робити дуууже не хочеться`,
    `Шукаєш привід для радості? Ти виграв безкоштовний круїз навколо Сонця!`,
    `Сьогодні порадуй близьких. Або хоча б не сильно засмути`,
    `Як нарешті сказати все, що думаєш? Головна порада – вголос`,
    `Коли знайдеш правильну відповідь, зміняться всі запитання`,
    `Коли не знаєш, що сказати, кажи «капучино»`,
    `Шанси виграти трохи зростають, якщо придбати лотерейний квиток`,
    `Щоразу, як виявляється, що ти неправий, привітай себе: змінюватися цікаво`,
    `Запам'ятай формулу ідеальних відносин: коротка пам'ять плюс почуття гумору`,
    `Чекай несподіваного, і ніщо не застане тебе зненацька!`,
    `Зараз ти там, куди тебе привели вчорашні думки`,
    `Нудні рішення ведуть до нецікавих результатів. Подумай ще трохи`,
    `Ідеального часу та ідеального плану не існує. Допив каву? Час діяти`,
    `Якщо дивитися на всі мінуси життя, виставивши середній палець, побачиш плюси`,
    `Все зміниться на краще, щойно знайдеш потрібний плейліст`,
    `Якщо оминати всі неприємності, можна пройти повз всі задоволення`,
    `Якщо дуже хочеться зробити дурість, поквапся: випередять!`,
    `Якщо поруч нікого немає, саме час познайомитися з собою`,
    `Ти не можеш управляти вітром, але здатний розгорнути вітрила!`,
    `Ти – не результат обставин. Ти – результат власних рішень`,
    `Щоб дістатися до джерела, слід пливти проти течії!`,
    `Ідеальне місце для старту – там, де ти зараз`,
    `«Завтра» – одне з найнебезпечніших слів на світі. Почни сьогодні!`,
    `Ти – там, де твої думки. Подбай, щоб твої думки були там, де ти хочеш`,
    `Зірки світять тим, хто на них дивиться`,
    `Якщо ти не знайдеш шлях, ти прокладеш його. Сміливість – початок перемоги`,
    `Навіть якщо змінилися плани, залишайся вірним своїм принципам`,
    `Дива трапляються з диваками`,
    `Ти станеш по-справжньому сильним, коли навчишся сміятися з будь-чого`,
    `Нестача людей краще неправильних людей`,
    `Іноді те, що ти знаходиш, важливіше за те, що ти шукав`,
    `Цікаві думки в голові з'являються без попередження – придбай новий блокнот`,
    `Хвалися тим, що викликає натхнення, а не заздрість`,
    `«Твої» люди завжди поруч, і не має значення, в смартфоні або на табуретці`,
    `Не шукай сенс, – спробуй його створити`,
    `Очікування радості теж радість`,
    `Діяти – єдиний спосіб дізнатися напевно`,
    `Для змін потрібне бажання, а не понеділок`,
    `Якщо хочеш, щоб у тебе нічого не вийшло, намагайся догодити всім і роби це постійно`,
    `Лови хвилю впевненості в собі – вона винесе тебе на новий берег`,
    `Не роби якомога більше, – роби тільки важливе`,
    `Якщо ти знаєш, навіщо, ти завжди знайдеш, як`,
    `Все найкраще трапляється несподівано`,
    `Годі до себе чіплятися, це забирає забагато сил`,
    `У хитрого зайця три нори – тобі потрібен запасний план`,
    `Прокинься! Роблячи вдих, усвідом повітря. Роблячи крок, відчуй дотик до землі`,
    `Саме час згадати: мета – це мрія з дедлайном`,
    `Не шукай одноріга, сидячи на ньому верхи – ти вже маєш все необхідне для щастя`,
    `Сьогоднішні досягнення – це вчорашні неймовірності`,
    `Інколи люди не знають, чого хочуть, доки їм це не запропонують. Ще кави?`,
    `Будь реалістом – прагни неможливого!`,
    `Читай, читай, читай. Книги, погоду, обличчя людей`,
    `Можливо все. На неможливе просто потрібно більше часу`,
    `Ти письменник своєї долі, а не читач чужої`,
    `Не потрібно ловити змію, краще прийняти протиотруту. Шкодять не чиїсь помилки, а твоя реакція на них`,
    `Йди, і обрій розшириться!`,
    `Справжнім друзям не забудь, подзвони`,
    `Ти неодмінно робитимеш дурниці, тож роби їх з ентузіазмом`,
    `Заблукати – найкращий спосіб знайти щось цікаве`,
    `Простий добрий вчинок додасть тобі сил`,
    `Доки ти дивишся на чайник, він не закипить. Не підглядай за здійсненням бажань`,
    `Читай людей між рядків – там не буває помилок`,
    `Частіше відкладай смартфон, щоб відчути смак кави та настрій друзів`,
    `Не обіцяй розпочати нове життя, встанови будильник на 30 хвилин раніше`,
    `Найкращий спосіб здолати свої почуття – це перетворити їх на вірші`,
    `Винен? Вибачся. Вислухай. Виправ`,
    `В твоїй добі стільки ж часу, скільки у Мікеланджело і Ейнштейна. Користуйся!`,
    `Дивись не на проблему, а на шлях виходу з проблеми`,
    `Не дивися, скільки ще потрібно зробити. Здивуйся, скільки вже зроблено`,
    `В тебе є ти. Ви впораєтеся лише разом`,
    `В тобі так міцно спить геній, що без додаткової чашки кави не обійтися`,
    `Це норм, що ти комусь не подобаєшся. Не всі мають гарний смак`,
    `Слухай пораду: ніколи не слухай непрохані поради`,
    `Знайди того, хто дивитиметься на тебе так, як ти дивишься на шоколадний пиріг`,
    `Перш ніж змінювати світ, зміни звичні маршрути`,
    `Не висушуй ставок, щоб дістати рибу – думай про довгострокову перспективу`,
    `Не треба лізти на дерево в пошуках риби, якщо не йшов рибний дощ`,
    `Дивись в людей як в дзеркало, вивчай свої чесноти і вади`,
    `Намалювавши змію, не домальовуй їй ноги. Іноді краще недоробити, ніж перестаратися`,
    `Не виходь з себе. Хтозна, що може статися за твоєї відсутності`,
    `Парасоля потрібна до того, як промокнеш. Тож думай заздалегідь – або радій дощу`,
    `Завжди є той, хто зробив це до тебе. Тож зроби це краще`,
    `Твої мрії – твій телепорт в майбутнє`,
    `Ти ніколи не отримаєш достатньо того, чого насправді не хочеш`,
    `Май всередині себе власну погоду. Байдуже, на вулиці дощ або сонце`,
    `Очікуй найкращого. Підготуйся до найгіршого. Скористайся тим, що відбувається`,
    `Чорний кіт перебіжить тобі дорогу лише тому, що він поспішатиме. Не ускладнюй`,
    `Найсмачніша кава – та, яку п'єш в дорозі. Подорожуй, щоб знову знайти себе`,
];